import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

const ReportList = lazy(() => import('./ReportListing'))
const ReportForm = lazy(() => import('./ReportForm'))
const ReportActivity = lazy(() => import('./ReportActivityListing'))

export const REPORT_PATH = {
  DASHBOARD: '/report',
  NEWREPORT: '/report/report-new',
  EDITREPORT: '/report/report-edit',
  ACTIVITY: '/report/report-activity',
}

const ReportRoutes:RouteObject[] = [
  {
    path: "",
    element: <ReportList />
  },
  {
    path: "report-new",
    element: <ReportForm mode="add"/> 
  },
  {
    path: `report-edit/:reportId`,
    element: <ReportForm mode="edit" />
  },
  {
    path: `report-activity/:reportId`,
    element: <ReportActivity /> 
  }
]

export default ReportRoutes
