import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSONObject: any;
  Upload: any;
};

/** JsonType */
export type Address = {
  __typename?: 'Address';
  AddressType?: Maybe<AddressType>;
  ID?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  siteName?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  AddressType?: InputMaybe<AddressType>;
  ID?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  postCode?: InputMaybe<Scalars['String']>;
  siteName?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

export enum AddressType {
  Delivery = 'DELIVERY',
  Primary = 'PRIMARY',
  Site = 'SITE'
}

export type Amenities = {
  __typename?: 'Amenities';
  category: Scalars['String'];
  items: Array<Scalars['String']>;
};

export type AmenitiesInput = {
  category: Scalars['String'];
  items: Array<Scalars['String']>;
};

export type AuditEntity = {
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
};

export type BaseInput = {
  ID?: InputMaybe<Scalars['String']>;
  commonStatus?: InputMaybe<CommonStatus>;
  createdBy?: InputMaybe<Scalars['String']>;
};

export type CloudStorageOutput = {
  __typename?: 'CloudStorageOutput';
  BucketFileName: Scalars['String'];
  FileName: Scalars['String'];
  IsPrivate?: Maybe<Scalars['Boolean']>;
  MediaType: Scalars['String'];
  PrivateUrl?: Maybe<Scalars['String']>;
  Url: Scalars['String'];
};

export enum CommonStatus {
  Active = 'ACTIVE',
  Block = 'BLOCK',
  Inactive = 'INACTIVE'
}

/** JsonType */
export type ContactPerson = {
  __typename?: 'ContactPerson';
  ID?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ContactPersonInput = {
  ID?: InputMaybe<Scalars['String']>;
  associateID?: InputMaybe<Scalars['String']>;
  contactNo?: InputMaybe<Scalars['String']>;
  designation?: InputMaybe<Scalars['String']>;
  docRefTable?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CoreBaseEntity = AuditEntity & {
  __typename?: 'CoreBaseEntity';
  ID: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
};

export type CreateAuditInput = {
  createdBy?: InputMaybe<Scalars['String']>;
};

export type CreateReportActivityInput = {
  activityType: Scalars['String'];
  reportId: Scalars['String'];
};

export type DataSource = {
  __typename?: 'DataSource';
  Columns?: Maybe<Array<Scalars['String']>>;
  DataMember: Scalars['String'];
  DataSource: Scalars['String'];
  DynamicFilterColumn?: Maybe<Scalars['String']>;
  IsMainQuery?: Maybe<Scalars['Boolean']>;
  IsStoreProcedure?: Maybe<Scalars['Boolean']>;
};

export type DataSourceInput = {
  Columns?: InputMaybe<Array<Scalars['String']>>;
  DataMember: Scalars['String'];
  DataSource: Scalars['String'];
  DynamicFilterColumn?: InputMaybe<Scalars['String']>;
  IsMainQuery?: InputMaybe<Scalars['Boolean']>;
  IsStoreProcedure?: InputMaybe<Scalars['Boolean']>;
};

export type DataSourceOutput = {
  __typename?: 'DataSourceOutput';
  categoryType: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type DropDownDataSource = {
  __typename?: 'DropDownDataSource';
  DataMember: Scalars['String'];
  DataSource: Scalars['String'];
  parameters: Array<DropDownParamterInterface>;
};

export type DropDownDataSourceInput = {
  DataMember: Scalars['String'];
  DataSource: Scalars['String'];
  parameters: Array<DropDownDataSourceParameterInput>;
};

export type DropDownDataSourceParameterInput = {
  Name: Scalars['String'];
  ParameterName: Scalars['String'];
};

export type DropDownMember = {
  __typename?: 'DropDownMember';
  DynamicSource?: Maybe<DynamicSource>;
  StaticData?: Maybe<Array<StaticData>>;
  ValueSource: Scalars['String'];
};

export type DropDownMemberInput = {
  DynamicSource?: InputMaybe<DynamicSourceInput>;
  StaticData?: InputMaybe<Array<StaticDataInput>>;
  ValueSource: Scalars['String'];
};

export type DropDownParamterInterface = {
  __typename?: 'DropDownParamterInterface';
  Name: Scalars['String'];
  ParameterName: Scalars['String'];
};

export type DynamicSource = {
  __typename?: 'DynamicSource';
  DataSource: DropDownDataSource;
  DisplayMember: Scalars['String'];
  ValueMember: Scalars['String'];
};

export type DynamicSourceInput = {
  DataSource: DropDownDataSourceInput;
  DisplayMember: Scalars['String'];
  ValueMember: Scalars['String'];
};

export type EditReportInput = {
  name: Scalars['String'];
  reportId: Scalars['String'];
};

export type EditUserInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  phoneNo: Scalars['String'];
  roleId: Scalars['String'];
  superUser: Scalars['Boolean'];
  userId: Scalars['String'];
  userName: Scalars['String'];
};

export type FieldError = {
  __typename?: 'FieldError';
  field: Scalars['String'];
  message: Scalars['String'];
};

export type HuaweiStorageOutput = {
  __typename?: 'HuaweiStorageOutput';
  BucketFileName: Scalars['String'];
  FileName: Scalars['String'];
  IsPrivate?: Maybe<Scalars['Boolean']>;
  MediaType: Scalars['String'];
  PrivateUrl?: Maybe<Scalars['String']>;
  Url: Scalars['String'];
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken: Scalars['String'];
  user: UserResponse;
};

export type MediaSource = {
  __typename?: 'MediaSource';
  caption?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  videoType?: Maybe<Scalars['String']>;
};

export type MediaSourceInput = {
  caption?: InputMaybe<Scalars['String']>;
  url: Scalars['String'];
  videoType?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addReportPermission: Scalars['Boolean'];
  addUser: Scalars['Boolean'];
  changePassword: Scalars['Boolean'];
  createReport: Scalars['Boolean'];
  createReportActivity: Scalars['Boolean'];
  createRole: Scalars['Boolean'];
  createSuperUser: UserEntity;
  deleteReport: Scalars['Boolean'];
  deleteRole: Scalars['Boolean'];
  editReport: Scalars['Boolean'];
  editReportPermission: Scalars['Boolean'];
  editRole: Scalars['Boolean'];
  editUser: Scalars['Boolean'];
  forgotPassword: Scalars['Boolean'];
  newPassword: Scalars['Boolean'];
  rebuildReport: Scalars['Boolean'];
  suspendUser: Scalars['Boolean'];
  transferReportOwner: Scalars['Boolean'];
  unBlockUser: Scalars['Boolean'];
  unSuspendUser: Scalars['Boolean'];
  updateProfile: Scalars['Boolean'];
};


export type MutationAddReportPermissionArgs = {
  input: ReportPermissionInput;
};


export type MutationAddUserArgs = {
  input: NewUserInput;
};


export type MutationChangePasswordArgs = {
  input: UserChangePasswordInput;
};


export type MutationCreateReportArgs = {
  input: NewReportInput;
};


export type MutationCreateReportActivityArgs = {
  input: CreateReportActivityInput;
};


export type MutationCreateRoleArgs = {
  permissionList?: InputMaybe<Array<Scalars['Float']>>;
  roleName: Scalars['String'];
};


export type MutationCreateSuperUserArgs = {
  accountID: Scalars['String'];
  input: UserInput;
};


export type MutationDeleteReportArgs = {
  reportId: Scalars['String'];
};


export type MutationDeleteRoleArgs = {
  roleId: Scalars['String'];
};


export type MutationEditReportArgs = {
  input: EditReportInput;
};


export type MutationEditReportPermissionArgs = {
  input: ReportPermissionInput;
};


export type MutationEditRoleArgs = {
  permissionList?: InputMaybe<Array<Scalars['Float']>>;
  roleId: Scalars['String'];
  roleName: Scalars['String'];
};


export type MutationEditUserArgs = {
  input: EditUserInput;
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationNewPasswordArgs = {
  confirmPassword: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationRebuildReportArgs = {
  reportId: Scalars['String'];
};


export type MutationSuspendUserArgs = {
  userId: Scalars['String'];
};


export type MutationTransferReportOwnerArgs = {
  newOwnerId: Scalars['String'];
  reportId: Scalars['String'];
};


export type MutationUnBlockUserArgs = {
  newPassword: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationUnSuspendUserArgs = {
  userId: Scalars['String'];
};


export type MutationUpdateProfileArgs = {
  input: UserContactInput;
};

export type NewReportInput = {
  dataSourceId: Scalars['String'];
  name: Scalars['String'];
};

export type NewUserInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  phoneNo: Scalars['String'];
  roleId: Scalars['String'];
  superUser: Scalars['Boolean'];
  userName: Scalars['String'];
};

export type PassswordErrorMsgOuput = {
  __typename?: 'PassswordErrorMsgOuput';
  RequireDigit: Scalars['String'];
  RequireLowercase: Scalars['String'];
  RequireNonAlphanumeric: Scalars['String'];
  RequireUppercase: Scalars['String'];
  RequiredLength: Scalars['String'];
  RequiredUniqueChars: Scalars['String'];
};

export type PassswordPolicyOuput = {
  __typename?: 'PassswordPolicyOuput';
  ErrorMsg: PassswordErrorMsgOuput;
  RequireDigit: Scalars['Boolean'];
  RequireLowercase: Scalars['Boolean'];
  RequireNonAlphanumeric: Scalars['Boolean'];
  RequireUppercase: Scalars['Boolean'];
  RequiredLength: Scalars['Float'];
  RequiredUniqueChars: Scalars['Float'];
};

export enum Permission {
  ReportDesignerAccessSecurityRoleListingCreate = 'REPORT_DESIGNER_ACCESS_SECURITY_ROLE_LISTING_______CREATE',
  ReportDesignerAccessSecurityRoleListingDelete = 'REPORT_DESIGNER_ACCESS_SECURITY_ROLE_LISTING_______DELETE',
  ReportDesignerAccessSecurityRoleListingUpdate = 'REPORT_DESIGNER_ACCESS_SECURITY_ROLE_LISTING_______UPDATE',
  ReportDesignerAccessSecurityRoleListingView = 'REPORT_DESIGNER_ACCESS_SECURITY_ROLE_LISTING_______VIEW',
  ReportDesignerAccessSecurityRoleView = 'REPORT_DESIGNER_ACCESS_SECURITY_ROLE__________VIEW',
  ReportDesignerAccessSecurityUserListingChangePassword = 'REPORT_DESIGNER_ACCESS_SECURITY_USER_LISTING_______CHANGE_PASSWORD',
  ReportDesignerAccessSecurityUserListingCreate = 'REPORT_DESIGNER_ACCESS_SECURITY_USER_LISTING_______CREATE',
  ReportDesignerAccessSecurityUserListingSuspend = 'REPORT_DESIGNER_ACCESS_SECURITY_USER_LISTING_______SUSPEND',
  ReportDesignerAccessSecurityUserListingUnblock = 'REPORT_DESIGNER_ACCESS_SECURITY_USER_LISTING_______UNBLOCK',
  ReportDesignerAccessSecurityUserListingUpdate = 'REPORT_DESIGNER_ACCESS_SECURITY_USER_LISTING_______UPDATE',
  ReportDesignerAccessSecurityUserListingView = 'REPORT_DESIGNER_ACCESS_SECURITY_USER_LISTING_______VIEW',
  ReportDesignerAccessSecurityUserView = 'REPORT_DESIGNER_ACCESS_SECURITY_USER__________VIEW',
  ReportDesignerAccessSecurityView = 'REPORT_DESIGNER_ACCESS_SECURITY_____________VIEW',
  ReportDesignerAccessSecurityView1245 = 'REPORT_DESIGNER_ACCESS_SECURITY_____________VIEW1245',
  ReportDesignerDataAccessAccountView = 'REPORT_DESIGNER_DATA_ACCESS_ACCOUNT__________VIEW',
  ReportDesignerDataAccessContractView = 'REPORT_DESIGNER_DATA_ACCESS_CONTRACT__________VIEW',
  ReportDesignerDataAccessHotelView = 'REPORT_DESIGNER_DATA_ACCESS_HOTEL__________VIEW',
  ReportDesignerDataAccessHrView = 'REPORT_DESIGNER_DATA_ACCESS_HR__________VIEW',
  ReportDesignerDataAccessPropertyView = 'REPORT_DESIGNER_DATA_ACCESS_PROPERTY__________VIEW',
  ReportDesignerReportListingActivityLog = 'REPORT_DESIGNER_REPORT_LISTING__________ACTIVITY_LOG',
  ReportDesignerReportListingCreate = 'REPORT_DESIGNER_REPORT_LISTING__________CREATE',
  ReportDesignerReportListingDelete = 'REPORT_DESIGNER_REPORT_LISTING__________DELETE',
  ReportDesignerReportListingDesign = 'REPORT_DESIGNER_REPORT_LISTING__________DESIGN',
  ReportDesignerReportListingUpdate = 'REPORT_DESIGNER_REPORT_LISTING__________UPDATE',
  ReportDesignerReportListingViewPrint = 'REPORT_DESIGNER_REPORT_LISTING__________VIEW_PRINT',
  ReportDesignerReportView = 'REPORT_DESIGNER_REPORT_____________VIEW'
}

export type PermissionDetail = {
  __typename?: 'PermissionDetail';
  child?: Maybe<Array<PermissionDetail>>;
  code?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  id: Scalars['String'];
  permissionId?: Maybe<Scalars['Float']>;
  permissionType?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  checkAvailableCreateReport: Scalars['Boolean'];
  checkReportDesignPermission: Scalars['Boolean'];
  getAccountName: Scalars['String'];
  getDataSourceList: Array<DataSourceOutput>;
  getPermission: Array<PermissionDetail>;
  getPermissionEnum: Scalars['JSONObject'];
  getPermissionEnumTxt: Scalars['Boolean'];
  getReport?: Maybe<UdfReport>;
  getReportActivity?: Maybe<Array<ReportActivityOutput>>;
  getReportActivityType: Array<Scalars['String']>;
  getReportCategoryListing: Array<ReportCategoryOutput>;
  getReportList: Array<ReportListingOutput>;
  getReportParametersList: Array<ReportParametersOutputs>;
  getReportPermissionSelectList: Array<ReportPermissionSelectedOutput>;
  getReportPermissionUnselectList: Array<ReportPermissionOutput>;
  getRoleById: RoleEntity;
  getRoles: Array<RoleEntity>;
  getTotalPermissionCount: Scalars['Float'];
  getUserDetails: UserEntity;
  getUserListing: Array<ReportPermissionOutput>;
  getUserStatus: Array<Scalars['String']>;
  login: Scalars['String'];
  logout: Scalars['Boolean'];
  passwordPolicy: PassswordPolicyOuput;
  profile: UserEntity;
  refreshToken?: Maybe<Scalars['String']>;
  userListing: Array<UserEntity>;
};


export type QueryCheckReportDesignPermissionArgs = {
  reportId: Scalars['String'];
};


export type QueryGetPermissionEnumTxtArgs = {
  Permission?: InputMaybe<Permission>;
};


export type QueryGetReportArgs = {
  reportId: Scalars['String'];
};


export type QueryGetReportActivityArgs = {
  reportId: Scalars['String'];
};


export type QueryGetReportListArgs = {
  categoryId: Scalars['String'];
};


export type QueryGetReportParametersListArgs = {
  dataSourceId: Scalars['String'];
  reportId: Scalars['String'];
};


export type QueryGetReportPermissionSelectListArgs = {
  action: Scalars['String'];
  reportId: Scalars['String'];
};


export type QueryGetReportPermissionUnselectListArgs = {
  action: Scalars['String'];
  reportId: Scalars['String'];
};


export type QueryGetRoleByIdArgs = {
  roleId: Scalars['String'];
};


export type QueryGetUserDetailsArgs = {
  userId: Scalars['String'];
};


export type QueryLoginArgs = {
  LoginId: Scalars['String'];
  Password: Scalars['String'];
};


export type QueryUserListingArgs = {
  activeStatus?: InputMaybe<Scalars['String']>;
  activeUserOnly?: InputMaybe<Scalars['Boolean']>;
  isSuperUser?: InputMaybe<Scalars['Boolean']>;
};

export type QueryFilterString = {
  __typename?: 'QueryFilterString';
  Column: Scalars['String'];
  Expression: Scalars['String'];
  ParameterName?: Maybe<Scalars['String']>;
  Value: Scalars['String'];
};

export type QueryFilterStringInput = {
  Column: Scalars['String'];
  Expression: Scalars['String'];
  ParameterName?: InputMaybe<Scalars['String']>;
  Value: Scalars['String'];
};

export type QueryParameter = {
  __typename?: 'QueryParameter';
  Name: Scalars['String'];
  Type?: Maybe<Scalars['String']>;
  Value: Scalars['String'];
  ValueType: Scalars['String'];
};

export type QueryParameterInput = {
  Name: Scalars['String'];
  Type?: InputMaybe<Scalars['String']>;
  Value: Scalars['String'];
  ValueType: Scalars['String'];
};

export type ReportActivityOutput = {
  __typename?: 'ReportActivityOutput';
  activityDate: Scalars['DateTime'];
  activityType: Scalars['String'];
  activityUser: Scalars['String'];
};

export type ReportCategoryOutput = {
  __typename?: 'ReportCategoryOutput';
  categoryId: Scalars['String'];
  categoryName: Scalars['String'];
};

export type ReportListingOutput = {
  __typename?: 'ReportListingOutput';
  allowEdit: Scalars['Boolean'];
  allowShare: Scalars['Boolean'];
  dataSourceId: Scalars['String'];
  isOwner: Scalars['Boolean'];
  isValidReport: Scalars['Boolean'];
  modTs: Scalars['DateTime'];
  ownerId: Scalars['String'];
  reportId: Scalars['String'];
  reportName: Scalars['String'];
  sharedBy: Scalars['String'];
  systemUse: Scalars['Boolean'];
};

export type ReportParameter = {
  __typename?: 'ReportParameter';
  AllowNull?: Maybe<Scalars['Boolean']>;
  Description: Scalars['String'];
  DropDownMember?: Maybe<DropDownMember>;
  GlobalType?: Maybe<Scalars['String']>;
  IsDateRange?: Maybe<Scalars['Boolean']>;
  MultiValue?: Maybe<Scalars['Boolean']>;
  Name: Scalars['String'];
  Tag?: Maybe<Scalars['String']>;
  Type: Scalars['String'];
  Visible?: Maybe<Scalars['Boolean']>;
};

export type ReportParameterInput = {
  AllowNull?: InputMaybe<Scalars['Boolean']>;
  Description: Scalars['String'];
  DropDownMember?: InputMaybe<DropDownMemberInput>;
  GlobalType?: InputMaybe<Scalars['String']>;
  IsDateRange?: InputMaybe<Scalars['Boolean']>;
  MultiValue?: InputMaybe<Scalars['Boolean']>;
  Name: Scalars['String'];
  Tag?: InputMaybe<Scalars['String']>;
  Type: Scalars['String'];
  Visible?: InputMaybe<Scalars['Boolean']>;
};

export type ReportParametersListingOutputs = {
  __typename?: 'ReportParametersListingOutputs';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type ReportParametersOutputs = {
  __typename?: 'ReportParametersOutputs';
  allowNull?: Maybe<Scalars['Boolean']>;
  isArray?: Maybe<Scalars['Boolean']>;
  isDateRange?: Maybe<Scalars['Boolean']>;
  listingData?: Maybe<Array<ReportParametersListingOutputs>>;
  multiValue?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  title: Scalars['String'];
  type: Scalars['String'];
};

export type ReportPermissionOutput = {
  __typename?: 'ReportPermissionOutput';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type ReportPermissionSelectedOutput = {
  __typename?: 'ReportPermissionSelectedOutput';
  name: Scalars['String'];
  permission: Scalars['String'];
  selectedId: Scalars['String'];
};

export type StaticData = {
  __typename?: 'StaticData';
  Desc: Scalars['String'];
  Value: Scalars['String'];
};

export type StaticDataInput = {
  Desc: Scalars['String'];
  Value: Scalars['String'];
};

export type TotpSecretTyes = {
  __typename?: 'TotpSecretTyes';
  dataURL?: Maybe<Scalars['String']>;
  is2FA?: Maybe<Scalars['Boolean']>;
  otpURL?: Maybe<Scalars['String']>;
  totpSecret?: Maybe<Scalars['String']>;
};

export type UpdateAuditInput = {
  modBy?: InputMaybe<Scalars['String']>;
};

export type UserChangePasswordInput = {
  confirmPassword: Scalars['String'];
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type UserContactInput = {
  avatar?: InputMaybe<Scalars['Upload']>;
  name: Scalars['String'];
  phoneNo: Scalars['String'];
};

export type UserEntity = AuditEntity & {
  __typename?: 'UserEntity';
  ID: Scalars['String'];
  accountID: Scalars['String'];
  address?: Maybe<Address>;
  avatar?: Maybe<Scalars['String']>;
  blockCount: Scalars['Float'];
  blockDuration: Scalars['Float'];
  commonStatus: CommonStatus;
  confirmed: Scalars['Boolean'];
  contactNo: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  department?: Maybe<Scalars['String']>;
  deviceIP?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  is2FA?: Maybe<Scalars['Boolean']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
  loginAttempt: Scalars['Float'];
  loginTs?: Maybe<Scalars['DateTime']>;
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  name?: Maybe<Scalars['String']>;
  softwareCode: Scalars['String'];
  superUser: Scalars['Boolean'];
  userName?: Maybe<Scalars['String']>;
  userPermission?: Maybe<Array<Scalars['Float']>>;
  userRoleId?: Maybe<Scalars['String']>;
  userRoleName?: Maybe<Scalars['String']>;
};

export type UserInput = {
  ID?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<AddressInput>;
  avatar?: InputMaybe<Scalars['String']>;
  commonStatus?: InputMaybe<CommonStatus>;
  contactNo: Scalars['String'];
  department?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  hashed?: InputMaybe<Scalars['String']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  superUser?: InputMaybe<Scalars['Boolean']>;
  userName?: InputMaybe<Scalars['String']>;
};

export type UserResponse = AuditEntity & {
  __typename?: 'UserResponse';
  ID: Scalars['String'];
  accountID: Scalars['String'];
  address?: Maybe<Address>;
  avatar?: Maybe<Scalars['String']>;
  blockCount?: Maybe<Scalars['Float']>;
  blockDuration?: Maybe<Scalars['Float']>;
  commonStatus: CommonStatus;
  confirmed: Scalars['Boolean'];
  contactNo: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  department?: Maybe<Scalars['String']>;
  deviceIP?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  errors?: Maybe<Array<FieldError>>;
  is2FA?: Maybe<Scalars['Boolean']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
  loginAttempt?: Maybe<Scalars['Float']>;
  loginTs?: Maybe<Scalars['DateTime']>;
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  name: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  superUser: Scalars['Boolean'];
  userName: Scalars['String'];
};

export type UserRoleAssignmentInput = {
  roleId: Scalars['String'];
  userIds: Array<Scalars['String']>;
};

export type AccessRight = {
  __typename?: 'accessRight';
  role: Array<RoleAccessRight>;
  user: Array<UserAccessRight>;
};

export type AccessRightInput = {
  role: Array<RoleAccessRightInput>;
  user: Array<UserAccessRightInput>;
};

export type EntityRoleUserAssignmentEntity = AuditEntity & {
  __typename?: 'entityRoleUserAssignmentEntity';
  accountId: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  entityId: Scalars['String'];
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  roleId: Scalars['String'];
  userId: Scalars['String'];
};

export type PasswordPolicyEntity = {
  __typename?: 'passwordPolicyEntity';
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  id: Scalars['String'];
  notAllowReusePasswordForLast?: Maybe<Scalars['Float']>;
  passwordResetByDay?: Maybe<Scalars['Float']>;
  requiredDigit: Scalars['Boolean'];
  requiredMinNoCharacter?: Maybe<Scalars['Float']>;
  requiredSpecialCharacter: Scalars['Boolean'];
  requiredUpperLowerCase: Scalars['Boolean'];
};

export type PermissionRoleAssignmentEntity = AuditEntity & {
  __typename?: 'permissionRoleAssignmentEntity';
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  permissionId: Scalars['Float'];
  roleId: Scalars['String'];
};

export type ProjectEntity = {
  __typename?: 'projectEntity';
  accountId: Scalars['String'];
  amenities?: Maybe<Array<Amenities>>;
  city?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
  completionDate?: Maybe<Scalars['DateTime']>;
  country: Scalars['String'];
  currency: Scalars['String'];
  desc?: Maybe<Scalars['String']>;
  developmentCompanyId: Scalars['String'];
  holdingCompanyId: Scalars['String'];
  id: Scalars['String'];
  isIntegrated: Scalars['Boolean'];
  landType: Scalars['String'];
  launchDate?: Maybe<Scalars['DateTime']>;
  maxPrice?: Maybe<Scalars['Float']>;
  minPrice?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  projectAddress?: Maybe<Scalars['String']>;
  projectCode: Scalars['String'];
  projectGps?: Maybe<Scalars['String']>;
  saleGalleryAddress?: Maybe<Scalars['String']>;
  saleGalleryGps?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  tenure: Scalars['String'];
  townshipName?: Maybe<Scalars['String']>;
  uom: Scalars['String'];
  videoLink?: Maybe<Array<MediaSource>>;
  virtualModelLink?: Maybe<Array<MediaSource>>;
  vpDate?: Maybe<Scalars['DateTime']>;
};

export type ReportPermissionDetailInput = {
  id: Scalars['String'];
  permission: Scalars['String'];
};

export type ReportPermissionEntity = AuditEntity & {
  __typename?: 'reportPermissionEntity';
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  permissionId: Scalars['Float'];
  roleId: Scalars['String'];
};

export type ReportPermissionInput = {
  action: Scalars['String'];
  permission: Array<ReportPermissionDetailInput>;
  reportId: Scalars['String'];
};

export type RoleAccessRight = {
  __typename?: 'roleAccessRight';
  permission: Scalars['String'];
  roleId: Scalars['String'];
};

export type RoleAccessRightInput = {
  permission: Scalars['String'];
  roleId: Scalars['String'];
};

export type RoleEntity = AuditEntity & {
  __typename?: 'roleEntity';
  accountId: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  name: Scalars['String'];
  permissions?: Maybe<Array<Scalars['Float']>>;
  softwareCode: Scalars['String'];
  totalPermission?: Maybe<Scalars['Float']>;
};

export type UdfDataSource = AuditEntity & {
  __typename?: 'udfDataSource';
  accountId: Scalars['String'];
  category: UdfReportCategoryTypeEntity;
  categoryTypeId: Scalars['String'];
  commonStatus: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  dataSource: DataSource;
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  name: Scalars['String'];
  queryFilterString?: Maybe<QueryFilterString>;
  queryParameter?: Maybe<QueryParameter>;
  reportParameter?: Maybe<ReportParameter>;
  softwareCode: Scalars['String'];
  version: Scalars['Float'];
};

export type UdfReport = AuditEntity & {
  __typename?: 'udfReport';
  accessRight: AccessRight;
  accountId: Scalars['String'];
  categoryTypeId: Scalars['String'];
  commonStatus: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  dataSource: UdfDataSource;
  dataSourceId: Scalars['String'];
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  name: Scalars['String'];
  ownerId?: Maybe<Scalars['String']>;
  permissionId: Scalars['Float'];
  reportActivities: Array<UdfReportActivity>;
  softwareCode: Scalars['String'];
  systemUse: Scalars['Boolean'];
  version: Scalars['Float'];
};

export type UdfReportActivity = AuditEntity & {
  __typename?: 'udfReportActivity';
  accountId: Scalars['String'];
  activityDate: Scalars['DateTime'];
  activityType: Scalars['String'];
  commonStatus: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  reportId: Scalars['String'];
};

export type UdfReportCategoryTypeEntity = AuditEntity & {
  __typename?: 'udfReportCategoryTypeEntity';
  accountId: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  dataSource: Array<UdfDataSource>;
  id: Scalars['String'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  name: Scalars['String'];
  softwareCode: Scalars['String'];
};

export type UserAccessRight = {
  __typename?: 'userAccessRight';
  permission: Scalars['String'];
  userId: Scalars['String'];
};

export type UserAccessRightInput = {
  permission: Scalars['String'];
  userId: Scalars['String'];
};

export type GetRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRolesQuery = { __typename?: 'Query', getTotalPermissionCount: number, getRoles: Array<{ __typename?: 'roleEntity', id: string, name: string, totalPermission?: number | null }> };

export type GetRolesMasterListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRolesMasterListQuery = { __typename?: 'Query', getRoles: Array<{ __typename?: 'roleEntity', id: string, name: string }> };

export type GetRoleByIdQueryVariables = Exact<{
  roleId: Scalars['String'];
}>;


export type GetRoleByIdQuery = { __typename?: 'Query', getRoleById: { __typename?: 'roleEntity', id: string, name: string, permissions?: Array<number> | null } };

export type GetPermisionListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPermisionListQuery = { __typename?: 'Query', getPermission: Array<{ __typename?: 'PermissionDetail', id: string, title: string, description: string, code?: string | null, permissionId?: number | null, child?: Array<{ __typename?: 'PermissionDetail', id: string, title: string, description: string, code?: string | null, permissionId?: number | null, child?: Array<{ __typename?: 'PermissionDetail', id: string, title: string, description: string, code?: string | null, permissionId?: number | null, child?: Array<{ __typename?: 'PermissionDetail', id: string, title: string, description: string, code?: string | null, permissionId?: number | null, child?: Array<{ __typename?: 'PermissionDetail', id: string, title: string, description: string, code?: string | null, permissionId?: number | null, child?: Array<{ __typename?: 'PermissionDetail', id: string, title: string, description: string, code?: string | null, permissionId?: number | null, child?: Array<{ __typename?: 'PermissionDetail', id: string, title: string, description: string, code?: string | null, permissionId?: number | null }> | null }> | null }> | null }> | null }> | null }> | null }> };

export type CreateRoleMutationVariables = Exact<{
  roleName: Scalars['String'];
  permissionList?: InputMaybe<Array<Scalars['Float']> | Scalars['Float']>;
}>;


export type CreateRoleMutation = { __typename?: 'Mutation', createRole: boolean };

export type EditRoleMutationVariables = Exact<{
  roleId: Scalars['String'];
  roleName: Scalars['String'];
  permissionList?: InputMaybe<Array<Scalars['Float']> | Scalars['Float']>;
}>;


export type EditRoleMutation = { __typename?: 'Mutation', editRole: boolean };

export type DeleteRoleMutationVariables = Exact<{
  roleId: Scalars['String'];
}>;


export type DeleteRoleMutation = { __typename?: 'Mutation', deleteRole: boolean };

export type GetPermissionEnumQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPermissionEnumQuery = { __typename?: 'Query', getPermissionEnum: any };

export type GetUserListPageQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserListPageQuery = { __typename?: 'Query', getUserStatus: Array<string>, userListing: Array<{ __typename?: 'UserEntity', ID: string, name?: string | null, email: string, confirmed: boolean, commonStatus: CommonStatus, superUser: boolean }> };

export type GetUserDetailQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type GetUserDetailQuery = { __typename?: 'Query', getUserDetails: { __typename?: 'UserEntity', ID: string, name?: string | null, userName?: string | null, contactNo: string, email: string, confirmed: boolean, commonStatus: CommonStatus, superUser: boolean, userRoleId?: string | null, userRoleName?: string | null } };

export type CreateUserMutationVariables = Exact<{
  input: NewUserInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', addUser: boolean };

export type EditUserMutationVariables = Exact<{
  input: EditUserInput;
}>;


export type EditUserMutation = { __typename?: 'Mutation', editUser: boolean };

export type SuspendUserMutationVariables = Exact<{
  userId: Scalars['String'];
}>;


export type SuspendUserMutation = { __typename?: 'Mutation', suspendUser: boolean };

export type UnSuspendUserMutationVariables = Exact<{
  userId: Scalars['String'];
}>;


export type UnSuspendUserMutation = { __typename?: 'Mutation', unSuspendUser: boolean };

export type UnBlockUserMutationVariables = Exact<{
  userId: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type UnBlockUserMutation = { __typename?: 'Mutation', unBlockUser: boolean };

export type PasswordPolicyQueryVariables = Exact<{ [key: string]: never; }>;


export type PasswordPolicyQuery = { __typename?: 'Query', passwordPolicy: { __typename?: 'PassswordPolicyOuput', RequireDigit: boolean, RequireLowercase: boolean, RequireNonAlphanumeric: boolean, RequireUppercase: boolean, RequiredUniqueChars: number, RequiredLength: number, ErrorMsg: { __typename?: 'PassswordErrorMsgOuput', RequireDigit: string, RequireLowercase: string, RequireNonAlphanumeric: string, RequireUppercase: string, RequiredLength: string, RequiredUniqueChars: string } } };

export type GetUserProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserProfileQuery = { __typename?: 'Query', profile: { __typename?: 'UserEntity', ID: string, avatar?: string | null, contactNo: string, email: string, name?: string | null, userPermission?: Array<number> | null, superUser: boolean } };

export type UpdateProfileMutationVariables = Exact<{
  input: UserContactInput;
}>;


export type UpdateProfileMutation = { __typename?: 'Mutation', updateProfile: boolean };

export type ChangePasswordMutationVariables = Exact<{
  input: UserChangePasswordInput;
}>;


export type ChangePasswordMutation = { __typename?: 'Mutation', changePassword: boolean };

export type AccountNameQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountNameQuery = { __typename?: 'Query', getAccountName: string };

export type LoginQueryVariables = Exact<{
  loginId: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginQuery = { __typename?: 'Query', login: string };

export type LogoutQueryVariables = Exact<{ [key: string]: never; }>;


export type LogoutQuery = { __typename?: 'Query', logout: boolean };

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = { __typename?: 'Mutation', forgotPassword: boolean };

export type NewPasswordMutationVariables = Exact<{
  newPassword: Scalars['String'];
  confirmNewPassword: Scalars['String'];
}>;


export type NewPasswordMutation = { __typename?: 'Mutation', newPassword: boolean };

export type GetReportParametersListQueryVariables = Exact<{
  dataSourceId: Scalars['String'];
  reportId: Scalars['String'];
}>;


export type GetReportParametersListQuery = { __typename?: 'Query', getReportParametersList: Array<{ __typename?: 'ReportParametersOutputs', name: string, type: string, isArray?: boolean | null, allowNull?: boolean | null, multiValue?: boolean | null, title: string, isDateRange?: boolean | null, listingData?: Array<{ __typename?: 'ReportParametersListingOutputs', id: string, name: string }> | null }> };

export type GetReportCategoryListingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetReportCategoryListingQuery = { __typename?: 'Query', getReportCategoryListing: Array<{ __typename?: 'ReportCategoryOutput', categoryId: string, categoryName: string }> };

export type GetReportListQueryVariables = Exact<{
  categoryId: Scalars['String'];
}>;


export type GetReportListQuery = { __typename?: 'Query', getReportList: Array<{ __typename?: 'ReportListingOutput', reportId: string, reportName: string, modTs: any, dataSourceId: string, ownerId: string, allowShare: boolean, allowEdit: boolean, systemUse: boolean, sharedBy: string, isOwner: boolean, isValidReport: boolean }> };

export type GetEditReportDataQueryVariables = Exact<{
  reportId: Scalars['String'];
}>;


export type GetEditReportDataQuery = { __typename?: 'Query', getReport?: { __typename?: 'udfReport', id: string, name: string, dataSource: { __typename?: 'udfDataSource', id: string, name: string } } | null };

export type GetCreateReportMasterDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCreateReportMasterDataQuery = { __typename?: 'Query', getDataSourceList: Array<{ __typename?: 'DataSourceOutput', id: string, name: string, categoryType: string }> };

export type CreateReportMutationVariables = Exact<{
  input: NewReportInput;
}>;


export type CreateReportMutation = { __typename?: 'Mutation', createReport: boolean };

export type EditReportMutationVariables = Exact<{
  input: EditReportInput;
}>;


export type EditReportMutation = { __typename?: 'Mutation', editReport: boolean };

export type DeleteReportMutationVariables = Exact<{
  reportId: Scalars['String'];
}>;


export type DeleteReportMutation = { __typename?: 'Mutation', deleteReport: boolean };

export type GetReportActivityQueryVariables = Exact<{
  reportId: Scalars['String'];
}>;


export type GetReportActivityQuery = { __typename?: 'Query', getReportActivityType: Array<string>, getReportActivity?: Array<{ __typename?: 'ReportActivityOutput', activityDate: any, activityType: string, activityUser: string }> | null };

export type CreateReportActivityMutationVariables = Exact<{
  input: CreateReportActivityInput;
}>;


export type CreateReportActivityMutation = { __typename?: 'Mutation', createReportActivity: boolean };

export type CheckReportDesignPermissionQueryVariables = Exact<{
  reportId: Scalars['String'];
}>;


export type CheckReportDesignPermissionQuery = { __typename?: 'Query', checkReportDesignPermission: boolean };

export type RebuildReportMutationVariables = Exact<{
  reportId: Scalars['String'];
}>;


export type RebuildReportMutation = { __typename?: 'Mutation', rebuildReport: boolean };

export type CheckAvailableCreateReportQueryVariables = Exact<{ [key: string]: never; }>;


export type CheckAvailableCreateReportQuery = { __typename?: 'Query', checkAvailableCreateReport: boolean };

export type GetReportPermissionUnselectListQueryVariables = Exact<{
  reportId: Scalars['String'];
  action: Scalars['String'];
}>;


export type GetReportPermissionUnselectListQuery = { __typename?: 'Query', getReportPermissionUnselectList: Array<{ __typename?: 'ReportPermissionOutput', id: string, name: string }> };

export type GetReportPermissionSelectListQueryVariables = Exact<{
  reportId: Scalars['String'];
  action: Scalars['String'];
}>;


export type GetReportPermissionSelectListQuery = { __typename?: 'Query', getReportPermissionSelectList: Array<{ __typename?: 'ReportPermissionSelectedOutput', selectedId: string, name: string, permission: string }> };

export type GetUserListingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserListingQuery = { __typename?: 'Query', getUserListing: Array<{ __typename?: 'ReportPermissionOutput', id: string, name: string }> };

export type AddReportPermissionMutationVariables = Exact<{
  input: ReportPermissionInput;
}>;


export type AddReportPermissionMutation = { __typename?: 'Mutation', addReportPermission: boolean };

export type EditReportPermissionMutationVariables = Exact<{
  input: ReportPermissionInput;
}>;


export type EditReportPermissionMutation = { __typename?: 'Mutation', editReportPermission: boolean };

export type TransferReportOwnerMutationVariables = Exact<{
  reportId: Scalars['String'];
  newOwnerId: Scalars['String'];
}>;


export type TransferReportOwnerMutation = { __typename?: 'Mutation', transferReportOwner: boolean };


export const GetRolesDocument = gql`
    query GetRoles {
  getRoles {
    id
    name
    totalPermission
  }
  getTotalPermissionCount
}
    `;

/**
 * __useGetRolesQuery__
 *
 * To run a query within a React component, call `useGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
      }
export function useGetRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
        }
export type GetRolesQueryHookResult = ReturnType<typeof useGetRolesQuery>;
export type GetRolesLazyQueryHookResult = ReturnType<typeof useGetRolesLazyQuery>;
export type GetRolesQueryResult = Apollo.QueryResult<GetRolesQuery, GetRolesQueryVariables>;
export const GetRolesMasterListDocument = gql`
    query GetRolesMasterList {
  getRoles {
    id
    name
  }
}
    `;

/**
 * __useGetRolesMasterListQuery__
 *
 * To run a query within a React component, call `useGetRolesMasterListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesMasterListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesMasterListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRolesMasterListQuery(baseOptions?: Apollo.QueryHookOptions<GetRolesMasterListQuery, GetRolesMasterListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRolesMasterListQuery, GetRolesMasterListQueryVariables>(GetRolesMasterListDocument, options);
      }
export function useGetRolesMasterListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRolesMasterListQuery, GetRolesMasterListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRolesMasterListQuery, GetRolesMasterListQueryVariables>(GetRolesMasterListDocument, options);
        }
export type GetRolesMasterListQueryHookResult = ReturnType<typeof useGetRolesMasterListQuery>;
export type GetRolesMasterListLazyQueryHookResult = ReturnType<typeof useGetRolesMasterListLazyQuery>;
export type GetRolesMasterListQueryResult = Apollo.QueryResult<GetRolesMasterListQuery, GetRolesMasterListQueryVariables>;
export const GetRoleByIdDocument = gql`
    query GetRoleById($roleId: String!) {
  getRoleById(roleId: $roleId) {
    id
    name
    permissions
  }
}
    `;

/**
 * __useGetRoleByIdQuery__
 *
 * To run a query within a React component, call `useGetRoleByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleByIdQuery({
 *   variables: {
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useGetRoleByIdQuery(baseOptions: Apollo.QueryHookOptions<GetRoleByIdQuery, GetRoleByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoleByIdQuery, GetRoleByIdQueryVariables>(GetRoleByIdDocument, options);
      }
export function useGetRoleByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoleByIdQuery, GetRoleByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoleByIdQuery, GetRoleByIdQueryVariables>(GetRoleByIdDocument, options);
        }
export type GetRoleByIdQueryHookResult = ReturnType<typeof useGetRoleByIdQuery>;
export type GetRoleByIdLazyQueryHookResult = ReturnType<typeof useGetRoleByIdLazyQuery>;
export type GetRoleByIdQueryResult = Apollo.QueryResult<GetRoleByIdQuery, GetRoleByIdQueryVariables>;
export const GetPermisionListDocument = gql`
    query GetPermisionList {
  getPermission {
    id
    title
    description
    code
    permissionId
    child {
      id
      title
      description
      code
      permissionId
      child {
        id
        title
        description
        code
        permissionId
        child {
          id
          title
          description
          code
          permissionId
          child {
            id
            title
            description
            code
            permissionId
            child {
              id
              title
              description
              code
              permissionId
              child {
                id
                title
                description
                code
                permissionId
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetPermisionListQuery__
 *
 * To run a query within a React component, call `useGetPermisionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPermisionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPermisionListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPermisionListQuery(baseOptions?: Apollo.QueryHookOptions<GetPermisionListQuery, GetPermisionListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPermisionListQuery, GetPermisionListQueryVariables>(GetPermisionListDocument, options);
      }
export function useGetPermisionListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPermisionListQuery, GetPermisionListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPermisionListQuery, GetPermisionListQueryVariables>(GetPermisionListDocument, options);
        }
export type GetPermisionListQueryHookResult = ReturnType<typeof useGetPermisionListQuery>;
export type GetPermisionListLazyQueryHookResult = ReturnType<typeof useGetPermisionListLazyQuery>;
export type GetPermisionListQueryResult = Apollo.QueryResult<GetPermisionListQuery, GetPermisionListQueryVariables>;
export const CreateRoleDocument = gql`
    mutation CreateRole($roleName: String!, $permissionList: [Float!]) {
  createRole(roleName: $roleName, permissionList: $permissionList)
}
    `;
export type CreateRoleMutationFn = Apollo.MutationFunction<CreateRoleMutation, CreateRoleMutationVariables>;

/**
 * __useCreateRoleMutation__
 *
 * To run a mutation, you first call `useCreateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleMutation, { data, loading, error }] = useCreateRoleMutation({
 *   variables: {
 *      roleName: // value for 'roleName'
 *      permissionList: // value for 'permissionList'
 *   },
 * });
 */
export function useCreateRoleMutation(baseOptions?: Apollo.MutationHookOptions<CreateRoleMutation, CreateRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRoleMutation, CreateRoleMutationVariables>(CreateRoleDocument, options);
      }
export type CreateRoleMutationHookResult = ReturnType<typeof useCreateRoleMutation>;
export type CreateRoleMutationResult = Apollo.MutationResult<CreateRoleMutation>;
export type CreateRoleMutationOptions = Apollo.BaseMutationOptions<CreateRoleMutation, CreateRoleMutationVariables>;
export const EditRoleDocument = gql`
    mutation EditRole($roleId: String!, $roleName: String!, $permissionList: [Float!]) {
  editRole(roleId: $roleId, roleName: $roleName, permissionList: $permissionList)
}
    `;
export type EditRoleMutationFn = Apollo.MutationFunction<EditRoleMutation, EditRoleMutationVariables>;

/**
 * __useEditRoleMutation__
 *
 * To run a mutation, you first call `useEditRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRoleMutation, { data, loading, error }] = useEditRoleMutation({
 *   variables: {
 *      roleId: // value for 'roleId'
 *      roleName: // value for 'roleName'
 *      permissionList: // value for 'permissionList'
 *   },
 * });
 */
export function useEditRoleMutation(baseOptions?: Apollo.MutationHookOptions<EditRoleMutation, EditRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditRoleMutation, EditRoleMutationVariables>(EditRoleDocument, options);
      }
export type EditRoleMutationHookResult = ReturnType<typeof useEditRoleMutation>;
export type EditRoleMutationResult = Apollo.MutationResult<EditRoleMutation>;
export type EditRoleMutationOptions = Apollo.BaseMutationOptions<EditRoleMutation, EditRoleMutationVariables>;
export const DeleteRoleDocument = gql`
    mutation DeleteRole($roleId: String!) {
  deleteRole(roleId: $roleId)
}
    `;
export type DeleteRoleMutationFn = Apollo.MutationFunction<DeleteRoleMutation, DeleteRoleMutationVariables>;

/**
 * __useDeleteRoleMutation__
 *
 * To run a mutation, you first call `useDeleteRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoleMutation, { data, loading, error }] = useDeleteRoleMutation({
 *   variables: {
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useDeleteRoleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRoleMutation, DeleteRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRoleMutation, DeleteRoleMutationVariables>(DeleteRoleDocument, options);
      }
export type DeleteRoleMutationHookResult = ReturnType<typeof useDeleteRoleMutation>;
export type DeleteRoleMutationResult = Apollo.MutationResult<DeleteRoleMutation>;
export type DeleteRoleMutationOptions = Apollo.BaseMutationOptions<DeleteRoleMutation, DeleteRoleMutationVariables>;
export const GetPermissionEnumDocument = gql`
    query GetPermissionEnum {
  getPermissionEnum
}
    `;

/**
 * __useGetPermissionEnumQuery__
 *
 * To run a query within a React component, call `useGetPermissionEnumQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPermissionEnumQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPermissionEnumQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPermissionEnumQuery(baseOptions?: Apollo.QueryHookOptions<GetPermissionEnumQuery, GetPermissionEnumQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPermissionEnumQuery, GetPermissionEnumQueryVariables>(GetPermissionEnumDocument, options);
      }
export function useGetPermissionEnumLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPermissionEnumQuery, GetPermissionEnumQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPermissionEnumQuery, GetPermissionEnumQueryVariables>(GetPermissionEnumDocument, options);
        }
export type GetPermissionEnumQueryHookResult = ReturnType<typeof useGetPermissionEnumQuery>;
export type GetPermissionEnumLazyQueryHookResult = ReturnType<typeof useGetPermissionEnumLazyQuery>;
export type GetPermissionEnumQueryResult = Apollo.QueryResult<GetPermissionEnumQuery, GetPermissionEnumQueryVariables>;
export const GetUserListPageDocument = gql`
    query getUserListPage {
  getUserStatus
  userListing {
    ID
    name
    email
    confirmed
    commonStatus
    superUser
  }
}
    `;

/**
 * __useGetUserListPageQuery__
 *
 * To run a query within a React component, call `useGetUserListPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserListPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserListPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserListPageQuery(baseOptions?: Apollo.QueryHookOptions<GetUserListPageQuery, GetUserListPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserListPageQuery, GetUserListPageQueryVariables>(GetUserListPageDocument, options);
      }
export function useGetUserListPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserListPageQuery, GetUserListPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserListPageQuery, GetUserListPageQueryVariables>(GetUserListPageDocument, options);
        }
export type GetUserListPageQueryHookResult = ReturnType<typeof useGetUserListPageQuery>;
export type GetUserListPageLazyQueryHookResult = ReturnType<typeof useGetUserListPageLazyQuery>;
export type GetUserListPageQueryResult = Apollo.QueryResult<GetUserListPageQuery, GetUserListPageQueryVariables>;
export const GetUserDetailDocument = gql`
    query GetUserDetail($userId: String!) {
  getUserDetails(userId: $userId) {
    ID
    name
    userName
    contactNo
    email
    confirmed
    commonStatus
    superUser
    userRoleId
    userRoleName
  }
}
    `;

/**
 * __useGetUserDetailQuery__
 *
 * To run a query within a React component, call `useGetUserDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDetailQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserDetailQuery(baseOptions: Apollo.QueryHookOptions<GetUserDetailQuery, GetUserDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserDetailQuery, GetUserDetailQueryVariables>(GetUserDetailDocument, options);
      }
export function useGetUserDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserDetailQuery, GetUserDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserDetailQuery, GetUserDetailQueryVariables>(GetUserDetailDocument, options);
        }
export type GetUserDetailQueryHookResult = ReturnType<typeof useGetUserDetailQuery>;
export type GetUserDetailLazyQueryHookResult = ReturnType<typeof useGetUserDetailLazyQuery>;
export type GetUserDetailQueryResult = Apollo.QueryResult<GetUserDetailQuery, GetUserDetailQueryVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($input: NewUserInput!) {
  addUser(input: $input)
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const EditUserDocument = gql`
    mutation EditUser($input: EditUserInput!) {
  editUser(input: $input)
}
    `;
export type EditUserMutationFn = Apollo.MutationFunction<EditUserMutation, EditUserMutationVariables>;

/**
 * __useEditUserMutation__
 *
 * To run a mutation, you first call `useEditUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserMutation, { data, loading, error }] = useEditUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditUserMutation(baseOptions?: Apollo.MutationHookOptions<EditUserMutation, EditUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditUserMutation, EditUserMutationVariables>(EditUserDocument, options);
      }
export type EditUserMutationHookResult = ReturnType<typeof useEditUserMutation>;
export type EditUserMutationResult = Apollo.MutationResult<EditUserMutation>;
export type EditUserMutationOptions = Apollo.BaseMutationOptions<EditUserMutation, EditUserMutationVariables>;
export const SuspendUserDocument = gql`
    mutation SuspendUser($userId: String!) {
  suspendUser(userId: $userId)
}
    `;
export type SuspendUserMutationFn = Apollo.MutationFunction<SuspendUserMutation, SuspendUserMutationVariables>;

/**
 * __useSuspendUserMutation__
 *
 * To run a mutation, you first call `useSuspendUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSuspendUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [suspendUserMutation, { data, loading, error }] = useSuspendUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSuspendUserMutation(baseOptions?: Apollo.MutationHookOptions<SuspendUserMutation, SuspendUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SuspendUserMutation, SuspendUserMutationVariables>(SuspendUserDocument, options);
      }
export type SuspendUserMutationHookResult = ReturnType<typeof useSuspendUserMutation>;
export type SuspendUserMutationResult = Apollo.MutationResult<SuspendUserMutation>;
export type SuspendUserMutationOptions = Apollo.BaseMutationOptions<SuspendUserMutation, SuspendUserMutationVariables>;
export const UnSuspendUserDocument = gql`
    mutation UnSuspendUser($userId: String!) {
  unSuspendUser(userId: $userId)
}
    `;
export type UnSuspendUserMutationFn = Apollo.MutationFunction<UnSuspendUserMutation, UnSuspendUserMutationVariables>;

/**
 * __useUnSuspendUserMutation__
 *
 * To run a mutation, you first call `useUnSuspendUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnSuspendUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unSuspendUserMutation, { data, loading, error }] = useUnSuspendUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUnSuspendUserMutation(baseOptions?: Apollo.MutationHookOptions<UnSuspendUserMutation, UnSuspendUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnSuspendUserMutation, UnSuspendUserMutationVariables>(UnSuspendUserDocument, options);
      }
export type UnSuspendUserMutationHookResult = ReturnType<typeof useUnSuspendUserMutation>;
export type UnSuspendUserMutationResult = Apollo.MutationResult<UnSuspendUserMutation>;
export type UnSuspendUserMutationOptions = Apollo.BaseMutationOptions<UnSuspendUserMutation, UnSuspendUserMutationVariables>;
export const UnBlockUserDocument = gql`
    mutation UnBlockUser($userId: String!, $newPassword: String!) {
  unBlockUser(userId: $userId, newPassword: $newPassword)
}
    `;
export type UnBlockUserMutationFn = Apollo.MutationFunction<UnBlockUserMutation, UnBlockUserMutationVariables>;

/**
 * __useUnBlockUserMutation__
 *
 * To run a mutation, you first call `useUnBlockUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnBlockUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unBlockUserMutation, { data, loading, error }] = useUnBlockUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useUnBlockUserMutation(baseOptions?: Apollo.MutationHookOptions<UnBlockUserMutation, UnBlockUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnBlockUserMutation, UnBlockUserMutationVariables>(UnBlockUserDocument, options);
      }
export type UnBlockUserMutationHookResult = ReturnType<typeof useUnBlockUserMutation>;
export type UnBlockUserMutationResult = Apollo.MutationResult<UnBlockUserMutation>;
export type UnBlockUserMutationOptions = Apollo.BaseMutationOptions<UnBlockUserMutation, UnBlockUserMutationVariables>;
export const PasswordPolicyDocument = gql`
    query PasswordPolicy {
  passwordPolicy {
    ErrorMsg {
      RequireDigit
      RequireLowercase
      RequireNonAlphanumeric
      RequireUppercase
      RequiredLength
      RequiredUniqueChars
    }
    RequireDigit
    RequireLowercase
    RequireNonAlphanumeric
    RequireUppercase
    RequiredUniqueChars
    RequiredLength
  }
}
    `;

/**
 * __usePasswordPolicyQuery__
 *
 * To run a query within a React component, call `usePasswordPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `usePasswordPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePasswordPolicyQuery({
 *   variables: {
 *   },
 * });
 */
export function usePasswordPolicyQuery(baseOptions?: Apollo.QueryHookOptions<PasswordPolicyQuery, PasswordPolicyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PasswordPolicyQuery, PasswordPolicyQueryVariables>(PasswordPolicyDocument, options);
      }
export function usePasswordPolicyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PasswordPolicyQuery, PasswordPolicyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PasswordPolicyQuery, PasswordPolicyQueryVariables>(PasswordPolicyDocument, options);
        }
export type PasswordPolicyQueryHookResult = ReturnType<typeof usePasswordPolicyQuery>;
export type PasswordPolicyLazyQueryHookResult = ReturnType<typeof usePasswordPolicyLazyQuery>;
export type PasswordPolicyQueryResult = Apollo.QueryResult<PasswordPolicyQuery, PasswordPolicyQueryVariables>;
export const GetUserProfileDocument = gql`
    query getUserProfile {
  profile {
    ID
    avatar
    contactNo
    email
    name
    userPermission
    superUser
  }
}
    `;

/**
 * __useGetUserProfileQuery__
 *
 * To run a query within a React component, call `useGetUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserProfileQuery(baseOptions?: Apollo.QueryHookOptions<GetUserProfileQuery, GetUserProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(GetUserProfileDocument, options);
      }
export function useGetUserProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserProfileQuery, GetUserProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(GetUserProfileDocument, options);
        }
export type GetUserProfileQueryHookResult = ReturnType<typeof useGetUserProfileQuery>;
export type GetUserProfileLazyQueryHookResult = ReturnType<typeof useGetUserProfileLazyQuery>;
export type GetUserProfileQueryResult = Apollo.QueryResult<GetUserProfileQuery, GetUserProfileQueryVariables>;
export const UpdateProfileDocument = gql`
    mutation UpdateProfile($input: UserContactInput!) {
  updateProfile(input: $input)
}
    `;
export type UpdateProfileMutationFn = Apollo.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, options);
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($input: UserChangePasswordInput!) {
  changePassword(input: $input)
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const AccountNameDocument = gql`
    query accountName {
  getAccountName
}
    `;

/**
 * __useAccountNameQuery__
 *
 * To run a query within a React component, call `useAccountNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountNameQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountNameQuery(baseOptions?: Apollo.QueryHookOptions<AccountNameQuery, AccountNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountNameQuery, AccountNameQueryVariables>(AccountNameDocument, options);
      }
export function useAccountNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountNameQuery, AccountNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountNameQuery, AccountNameQueryVariables>(AccountNameDocument, options);
        }
export type AccountNameQueryHookResult = ReturnType<typeof useAccountNameQuery>;
export type AccountNameLazyQueryHookResult = ReturnType<typeof useAccountNameLazyQuery>;
export type AccountNameQueryResult = Apollo.QueryResult<AccountNameQuery, AccountNameQueryVariables>;
export const LoginDocument = gql`
    query login($loginId: String!, $password: String!) {
  login(LoginId: $loginId, Password: $password)
}
    `;

/**
 * __useLoginQuery__
 *
 * To run a query within a React component, call `useLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginQuery({
 *   variables: {
 *      loginId: // value for 'loginId'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginQuery(baseOptions: Apollo.QueryHookOptions<LoginQuery, LoginQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoginQuery, LoginQueryVariables>(LoginDocument, options);
      }
export function useLoginLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoginQuery, LoginQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoginQuery, LoginQueryVariables>(LoginDocument, options);
        }
export type LoginQueryHookResult = ReturnType<typeof useLoginQuery>;
export type LoginLazyQueryHookResult = ReturnType<typeof useLoginLazyQuery>;
export type LoginQueryResult = Apollo.QueryResult<LoginQuery, LoginQueryVariables>;
export const LogoutDocument = gql`
    query logout {
  logout
}
    `;

/**
 * __useLogoutQuery__
 *
 * To run a query within a React component, call `useLogoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useLogoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogoutQuery({
 *   variables: {
 *   },
 * });
 */
export function useLogoutQuery(baseOptions?: Apollo.QueryHookOptions<LogoutQuery, LogoutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LogoutQuery, LogoutQueryVariables>(LogoutDocument, options);
      }
export function useLogoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LogoutQuery, LogoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LogoutQuery, LogoutQueryVariables>(LogoutDocument, options);
        }
export type LogoutQueryHookResult = ReturnType<typeof useLogoutQuery>;
export type LogoutLazyQueryHookResult = ReturnType<typeof useLogoutLazyQuery>;
export type LogoutQueryResult = Apollo.QueryResult<LogoutQuery, LogoutQueryVariables>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($email: String!) {
  forgotPassword(email: $email)
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const NewPasswordDocument = gql`
    mutation NewPassword($newPassword: String!, $confirmNewPassword: String!) {
  newPassword(newPassword: $newPassword, confirmPassword: $confirmNewPassword)
}
    `;
export type NewPasswordMutationFn = Apollo.MutationFunction<NewPasswordMutation, NewPasswordMutationVariables>;

/**
 * __useNewPasswordMutation__
 *
 * To run a mutation, you first call `useNewPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newPasswordMutation, { data, loading, error }] = useNewPasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      confirmNewPassword: // value for 'confirmNewPassword'
 *   },
 * });
 */
export function useNewPasswordMutation(baseOptions?: Apollo.MutationHookOptions<NewPasswordMutation, NewPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewPasswordMutation, NewPasswordMutationVariables>(NewPasswordDocument, options);
      }
export type NewPasswordMutationHookResult = ReturnType<typeof useNewPasswordMutation>;
export type NewPasswordMutationResult = Apollo.MutationResult<NewPasswordMutation>;
export type NewPasswordMutationOptions = Apollo.BaseMutationOptions<NewPasswordMutation, NewPasswordMutationVariables>;
export const GetReportParametersListDocument = gql`
    query getReportParametersList($dataSourceId: String!, $reportId: String!) {
  getReportParametersList(dataSourceId: $dataSourceId, reportId: $reportId) {
    name
    type
    isArray
    allowNull
    multiValue
    title
    isDateRange
    listingData {
      id
      name
    }
  }
}
    `;

/**
 * __useGetReportParametersListQuery__
 *
 * To run a query within a React component, call `useGetReportParametersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportParametersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportParametersListQuery({
 *   variables: {
 *      dataSourceId: // value for 'dataSourceId'
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useGetReportParametersListQuery(baseOptions: Apollo.QueryHookOptions<GetReportParametersListQuery, GetReportParametersListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReportParametersListQuery, GetReportParametersListQueryVariables>(GetReportParametersListDocument, options);
      }
export function useGetReportParametersListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReportParametersListQuery, GetReportParametersListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReportParametersListQuery, GetReportParametersListQueryVariables>(GetReportParametersListDocument, options);
        }
export type GetReportParametersListQueryHookResult = ReturnType<typeof useGetReportParametersListQuery>;
export type GetReportParametersListLazyQueryHookResult = ReturnType<typeof useGetReportParametersListLazyQuery>;
export type GetReportParametersListQueryResult = Apollo.QueryResult<GetReportParametersListQuery, GetReportParametersListQueryVariables>;
export const GetReportCategoryListingDocument = gql`
    query getReportCategoryListing {
  getReportCategoryListing {
    categoryId
    categoryName
  }
}
    `;

/**
 * __useGetReportCategoryListingQuery__
 *
 * To run a query within a React component, call `useGetReportCategoryListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportCategoryListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportCategoryListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetReportCategoryListingQuery(baseOptions?: Apollo.QueryHookOptions<GetReportCategoryListingQuery, GetReportCategoryListingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReportCategoryListingQuery, GetReportCategoryListingQueryVariables>(GetReportCategoryListingDocument, options);
      }
export function useGetReportCategoryListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReportCategoryListingQuery, GetReportCategoryListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReportCategoryListingQuery, GetReportCategoryListingQueryVariables>(GetReportCategoryListingDocument, options);
        }
export type GetReportCategoryListingQueryHookResult = ReturnType<typeof useGetReportCategoryListingQuery>;
export type GetReportCategoryListingLazyQueryHookResult = ReturnType<typeof useGetReportCategoryListingLazyQuery>;
export type GetReportCategoryListingQueryResult = Apollo.QueryResult<GetReportCategoryListingQuery, GetReportCategoryListingQueryVariables>;
export const GetReportListDocument = gql`
    query getReportList($categoryId: String!) {
  getReportList(categoryId: $categoryId) {
    reportId
    reportName
    modTs
    dataSourceId
    ownerId
    allowShare
    allowEdit
    systemUse
    sharedBy
    isOwner
    isValidReport
  }
}
    `;

/**
 * __useGetReportListQuery__
 *
 * To run a query within a React component, call `useGetReportListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportListQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useGetReportListQuery(baseOptions: Apollo.QueryHookOptions<GetReportListQuery, GetReportListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReportListQuery, GetReportListQueryVariables>(GetReportListDocument, options);
      }
export function useGetReportListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReportListQuery, GetReportListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReportListQuery, GetReportListQueryVariables>(GetReportListDocument, options);
        }
export type GetReportListQueryHookResult = ReturnType<typeof useGetReportListQuery>;
export type GetReportListLazyQueryHookResult = ReturnType<typeof useGetReportListLazyQuery>;
export type GetReportListQueryResult = Apollo.QueryResult<GetReportListQuery, GetReportListQueryVariables>;
export const GetEditReportDataDocument = gql`
    query getEditReportData($reportId: String!) {
  getReport(reportId: $reportId) {
    id
    name
    dataSource {
      id
      name
    }
  }
}
    `;

/**
 * __useGetEditReportDataQuery__
 *
 * To run a query within a React component, call `useGetEditReportDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditReportDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditReportDataQuery({
 *   variables: {
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useGetEditReportDataQuery(baseOptions: Apollo.QueryHookOptions<GetEditReportDataQuery, GetEditReportDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEditReportDataQuery, GetEditReportDataQueryVariables>(GetEditReportDataDocument, options);
      }
export function useGetEditReportDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEditReportDataQuery, GetEditReportDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEditReportDataQuery, GetEditReportDataQueryVariables>(GetEditReportDataDocument, options);
        }
export type GetEditReportDataQueryHookResult = ReturnType<typeof useGetEditReportDataQuery>;
export type GetEditReportDataLazyQueryHookResult = ReturnType<typeof useGetEditReportDataLazyQuery>;
export type GetEditReportDataQueryResult = Apollo.QueryResult<GetEditReportDataQuery, GetEditReportDataQueryVariables>;
export const GetCreateReportMasterDataDocument = gql`
    query getCreateReportMasterData {
  getDataSourceList {
    id
    name
    categoryType
  }
}
    `;

/**
 * __useGetCreateReportMasterDataQuery__
 *
 * To run a query within a React component, call `useGetCreateReportMasterDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreateReportMasterDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreateReportMasterDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCreateReportMasterDataQuery(baseOptions?: Apollo.QueryHookOptions<GetCreateReportMasterDataQuery, GetCreateReportMasterDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCreateReportMasterDataQuery, GetCreateReportMasterDataQueryVariables>(GetCreateReportMasterDataDocument, options);
      }
export function useGetCreateReportMasterDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCreateReportMasterDataQuery, GetCreateReportMasterDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCreateReportMasterDataQuery, GetCreateReportMasterDataQueryVariables>(GetCreateReportMasterDataDocument, options);
        }
export type GetCreateReportMasterDataQueryHookResult = ReturnType<typeof useGetCreateReportMasterDataQuery>;
export type GetCreateReportMasterDataLazyQueryHookResult = ReturnType<typeof useGetCreateReportMasterDataLazyQuery>;
export type GetCreateReportMasterDataQueryResult = Apollo.QueryResult<GetCreateReportMasterDataQuery, GetCreateReportMasterDataQueryVariables>;
export const CreateReportDocument = gql`
    mutation CreateReport($input: NewReportInput!) {
  createReport(input: $input)
}
    `;
export type CreateReportMutationFn = Apollo.MutationFunction<CreateReportMutation, CreateReportMutationVariables>;

/**
 * __useCreateReportMutation__
 *
 * To run a mutation, you first call `useCreateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReportMutation, { data, loading, error }] = useCreateReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateReportMutation, CreateReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReportMutation, CreateReportMutationVariables>(CreateReportDocument, options);
      }
export type CreateReportMutationHookResult = ReturnType<typeof useCreateReportMutation>;
export type CreateReportMutationResult = Apollo.MutationResult<CreateReportMutation>;
export type CreateReportMutationOptions = Apollo.BaseMutationOptions<CreateReportMutation, CreateReportMutationVariables>;
export const EditReportDocument = gql`
    mutation EditReport($input: EditReportInput!) {
  editReport(input: $input)
}
    `;
export type EditReportMutationFn = Apollo.MutationFunction<EditReportMutation, EditReportMutationVariables>;

/**
 * __useEditReportMutation__
 *
 * To run a mutation, you first call `useEditReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editReportMutation, { data, loading, error }] = useEditReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditReportMutation(baseOptions?: Apollo.MutationHookOptions<EditReportMutation, EditReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditReportMutation, EditReportMutationVariables>(EditReportDocument, options);
      }
export type EditReportMutationHookResult = ReturnType<typeof useEditReportMutation>;
export type EditReportMutationResult = Apollo.MutationResult<EditReportMutation>;
export type EditReportMutationOptions = Apollo.BaseMutationOptions<EditReportMutation, EditReportMutationVariables>;
export const DeleteReportDocument = gql`
    mutation DeleteReport($reportId: String!) {
  deleteReport(reportId: $reportId)
}
    `;
export type DeleteReportMutationFn = Apollo.MutationFunction<DeleteReportMutation, DeleteReportMutationVariables>;

/**
 * __useDeleteReportMutation__
 *
 * To run a mutation, you first call `useDeleteReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReportMutation, { data, loading, error }] = useDeleteReportMutation({
 *   variables: {
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useDeleteReportMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReportMutation, DeleteReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReportMutation, DeleteReportMutationVariables>(DeleteReportDocument, options);
      }
export type DeleteReportMutationHookResult = ReturnType<typeof useDeleteReportMutation>;
export type DeleteReportMutationResult = Apollo.MutationResult<DeleteReportMutation>;
export type DeleteReportMutationOptions = Apollo.BaseMutationOptions<DeleteReportMutation, DeleteReportMutationVariables>;
export const GetReportActivityDocument = gql`
    query GetReportActivity($reportId: String!) {
  getReportActivity(reportId: $reportId) {
    activityDate
    activityType
    activityUser
  }
  getReportActivityType
}
    `;

/**
 * __useGetReportActivityQuery__
 *
 * To run a query within a React component, call `useGetReportActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportActivityQuery({
 *   variables: {
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useGetReportActivityQuery(baseOptions: Apollo.QueryHookOptions<GetReportActivityQuery, GetReportActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReportActivityQuery, GetReportActivityQueryVariables>(GetReportActivityDocument, options);
      }
export function useGetReportActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReportActivityQuery, GetReportActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReportActivityQuery, GetReportActivityQueryVariables>(GetReportActivityDocument, options);
        }
export type GetReportActivityQueryHookResult = ReturnType<typeof useGetReportActivityQuery>;
export type GetReportActivityLazyQueryHookResult = ReturnType<typeof useGetReportActivityLazyQuery>;
export type GetReportActivityQueryResult = Apollo.QueryResult<GetReportActivityQuery, GetReportActivityQueryVariables>;
export const CreateReportActivityDocument = gql`
    mutation CreateReportActivity($input: CreateReportActivityInput!) {
  createReportActivity(input: $input)
}
    `;
export type CreateReportActivityMutationFn = Apollo.MutationFunction<CreateReportActivityMutation, CreateReportActivityMutationVariables>;

/**
 * __useCreateReportActivityMutation__
 *
 * To run a mutation, you first call `useCreateReportActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReportActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReportActivityMutation, { data, loading, error }] = useCreateReportActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReportActivityMutation(baseOptions?: Apollo.MutationHookOptions<CreateReportActivityMutation, CreateReportActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReportActivityMutation, CreateReportActivityMutationVariables>(CreateReportActivityDocument, options);
      }
export type CreateReportActivityMutationHookResult = ReturnType<typeof useCreateReportActivityMutation>;
export type CreateReportActivityMutationResult = Apollo.MutationResult<CreateReportActivityMutation>;
export type CreateReportActivityMutationOptions = Apollo.BaseMutationOptions<CreateReportActivityMutation, CreateReportActivityMutationVariables>;
export const CheckReportDesignPermissionDocument = gql`
    query checkReportDesignPermission($reportId: String!) {
  checkReportDesignPermission(reportId: $reportId)
}
    `;

/**
 * __useCheckReportDesignPermissionQuery__
 *
 * To run a query within a React component, call `useCheckReportDesignPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckReportDesignPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckReportDesignPermissionQuery({
 *   variables: {
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useCheckReportDesignPermissionQuery(baseOptions: Apollo.QueryHookOptions<CheckReportDesignPermissionQuery, CheckReportDesignPermissionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckReportDesignPermissionQuery, CheckReportDesignPermissionQueryVariables>(CheckReportDesignPermissionDocument, options);
      }
export function useCheckReportDesignPermissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckReportDesignPermissionQuery, CheckReportDesignPermissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckReportDesignPermissionQuery, CheckReportDesignPermissionQueryVariables>(CheckReportDesignPermissionDocument, options);
        }
export type CheckReportDesignPermissionQueryHookResult = ReturnType<typeof useCheckReportDesignPermissionQuery>;
export type CheckReportDesignPermissionLazyQueryHookResult = ReturnType<typeof useCheckReportDesignPermissionLazyQuery>;
export type CheckReportDesignPermissionQueryResult = Apollo.QueryResult<CheckReportDesignPermissionQuery, CheckReportDesignPermissionQueryVariables>;
export const RebuildReportDocument = gql`
    mutation rebuildReport($reportId: String!) {
  rebuildReport(reportId: $reportId)
}
    `;
export type RebuildReportMutationFn = Apollo.MutationFunction<RebuildReportMutation, RebuildReportMutationVariables>;

/**
 * __useRebuildReportMutation__
 *
 * To run a mutation, you first call `useRebuildReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRebuildReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rebuildReportMutation, { data, loading, error }] = useRebuildReportMutation({
 *   variables: {
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useRebuildReportMutation(baseOptions?: Apollo.MutationHookOptions<RebuildReportMutation, RebuildReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RebuildReportMutation, RebuildReportMutationVariables>(RebuildReportDocument, options);
      }
export type RebuildReportMutationHookResult = ReturnType<typeof useRebuildReportMutation>;
export type RebuildReportMutationResult = Apollo.MutationResult<RebuildReportMutation>;
export type RebuildReportMutationOptions = Apollo.BaseMutationOptions<RebuildReportMutation, RebuildReportMutationVariables>;
export const CheckAvailableCreateReportDocument = gql`
    query checkAvailableCreateReport {
  checkAvailableCreateReport
}
    `;

/**
 * __useCheckAvailableCreateReportQuery__
 *
 * To run a query within a React component, call `useCheckAvailableCreateReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckAvailableCreateReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckAvailableCreateReportQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckAvailableCreateReportQuery(baseOptions?: Apollo.QueryHookOptions<CheckAvailableCreateReportQuery, CheckAvailableCreateReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckAvailableCreateReportQuery, CheckAvailableCreateReportQueryVariables>(CheckAvailableCreateReportDocument, options);
      }
export function useCheckAvailableCreateReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckAvailableCreateReportQuery, CheckAvailableCreateReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckAvailableCreateReportQuery, CheckAvailableCreateReportQueryVariables>(CheckAvailableCreateReportDocument, options);
        }
export type CheckAvailableCreateReportQueryHookResult = ReturnType<typeof useCheckAvailableCreateReportQuery>;
export type CheckAvailableCreateReportLazyQueryHookResult = ReturnType<typeof useCheckAvailableCreateReportLazyQuery>;
export type CheckAvailableCreateReportQueryResult = Apollo.QueryResult<CheckAvailableCreateReportQuery, CheckAvailableCreateReportQueryVariables>;
export const GetReportPermissionUnselectListDocument = gql`
    query getReportPermissionUnselectList($reportId: String!, $action: String!) {
  getReportPermissionUnselectList(reportId: $reportId, action: $action) {
    id
    name
  }
}
    `;

/**
 * __useGetReportPermissionUnselectListQuery__
 *
 * To run a query within a React component, call `useGetReportPermissionUnselectListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportPermissionUnselectListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportPermissionUnselectListQuery({
 *   variables: {
 *      reportId: // value for 'reportId'
 *      action: // value for 'action'
 *   },
 * });
 */
export function useGetReportPermissionUnselectListQuery(baseOptions: Apollo.QueryHookOptions<GetReportPermissionUnselectListQuery, GetReportPermissionUnselectListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReportPermissionUnselectListQuery, GetReportPermissionUnselectListQueryVariables>(GetReportPermissionUnselectListDocument, options);
      }
export function useGetReportPermissionUnselectListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReportPermissionUnselectListQuery, GetReportPermissionUnselectListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReportPermissionUnselectListQuery, GetReportPermissionUnselectListQueryVariables>(GetReportPermissionUnselectListDocument, options);
        }
export type GetReportPermissionUnselectListQueryHookResult = ReturnType<typeof useGetReportPermissionUnselectListQuery>;
export type GetReportPermissionUnselectListLazyQueryHookResult = ReturnType<typeof useGetReportPermissionUnselectListLazyQuery>;
export type GetReportPermissionUnselectListQueryResult = Apollo.QueryResult<GetReportPermissionUnselectListQuery, GetReportPermissionUnselectListQueryVariables>;
export const GetReportPermissionSelectListDocument = gql`
    query getReportPermissionSelectList($reportId: String!, $action: String!) {
  getReportPermissionSelectList(reportId: $reportId, action: $action) {
    selectedId
    name
    permission
  }
}
    `;

/**
 * __useGetReportPermissionSelectListQuery__
 *
 * To run a query within a React component, call `useGetReportPermissionSelectListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportPermissionSelectListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportPermissionSelectListQuery({
 *   variables: {
 *      reportId: // value for 'reportId'
 *      action: // value for 'action'
 *   },
 * });
 */
export function useGetReportPermissionSelectListQuery(baseOptions: Apollo.QueryHookOptions<GetReportPermissionSelectListQuery, GetReportPermissionSelectListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReportPermissionSelectListQuery, GetReportPermissionSelectListQueryVariables>(GetReportPermissionSelectListDocument, options);
      }
export function useGetReportPermissionSelectListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReportPermissionSelectListQuery, GetReportPermissionSelectListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReportPermissionSelectListQuery, GetReportPermissionSelectListQueryVariables>(GetReportPermissionSelectListDocument, options);
        }
export type GetReportPermissionSelectListQueryHookResult = ReturnType<typeof useGetReportPermissionSelectListQuery>;
export type GetReportPermissionSelectListLazyQueryHookResult = ReturnType<typeof useGetReportPermissionSelectListLazyQuery>;
export type GetReportPermissionSelectListQueryResult = Apollo.QueryResult<GetReportPermissionSelectListQuery, GetReportPermissionSelectListQueryVariables>;
export const GetUserListingDocument = gql`
    query getUserListing {
  getUserListing {
    id
    name
  }
}
    `;

/**
 * __useGetUserListingQuery__
 *
 * To run a query within a React component, call `useGetUserListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserListingQuery(baseOptions?: Apollo.QueryHookOptions<GetUserListingQuery, GetUserListingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserListingQuery, GetUserListingQueryVariables>(GetUserListingDocument, options);
      }
export function useGetUserListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserListingQuery, GetUserListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserListingQuery, GetUserListingQueryVariables>(GetUserListingDocument, options);
        }
export type GetUserListingQueryHookResult = ReturnType<typeof useGetUserListingQuery>;
export type GetUserListingLazyQueryHookResult = ReturnType<typeof useGetUserListingLazyQuery>;
export type GetUserListingQueryResult = Apollo.QueryResult<GetUserListingQuery, GetUserListingQueryVariables>;
export const AddReportPermissionDocument = gql`
    mutation addReportPermission($input: reportPermissionInput!) {
  addReportPermission(input: $input)
}
    `;
export type AddReportPermissionMutationFn = Apollo.MutationFunction<AddReportPermissionMutation, AddReportPermissionMutationVariables>;

/**
 * __useAddReportPermissionMutation__
 *
 * To run a mutation, you first call `useAddReportPermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddReportPermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addReportPermissionMutation, { data, loading, error }] = useAddReportPermissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddReportPermissionMutation(baseOptions?: Apollo.MutationHookOptions<AddReportPermissionMutation, AddReportPermissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddReportPermissionMutation, AddReportPermissionMutationVariables>(AddReportPermissionDocument, options);
      }
export type AddReportPermissionMutationHookResult = ReturnType<typeof useAddReportPermissionMutation>;
export type AddReportPermissionMutationResult = Apollo.MutationResult<AddReportPermissionMutation>;
export type AddReportPermissionMutationOptions = Apollo.BaseMutationOptions<AddReportPermissionMutation, AddReportPermissionMutationVariables>;
export const EditReportPermissionDocument = gql`
    mutation editReportPermission($input: reportPermissionInput!) {
  editReportPermission(input: $input)
}
    `;
export type EditReportPermissionMutationFn = Apollo.MutationFunction<EditReportPermissionMutation, EditReportPermissionMutationVariables>;

/**
 * __useEditReportPermissionMutation__
 *
 * To run a mutation, you first call `useEditReportPermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditReportPermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editReportPermissionMutation, { data, loading, error }] = useEditReportPermissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditReportPermissionMutation(baseOptions?: Apollo.MutationHookOptions<EditReportPermissionMutation, EditReportPermissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditReportPermissionMutation, EditReportPermissionMutationVariables>(EditReportPermissionDocument, options);
      }
export type EditReportPermissionMutationHookResult = ReturnType<typeof useEditReportPermissionMutation>;
export type EditReportPermissionMutationResult = Apollo.MutationResult<EditReportPermissionMutation>;
export type EditReportPermissionMutationOptions = Apollo.BaseMutationOptions<EditReportPermissionMutation, EditReportPermissionMutationVariables>;
export const TransferReportOwnerDocument = gql`
    mutation transferReportOwner($reportId: String!, $newOwnerId: String!) {
  transferReportOwner(reportId: $reportId, newOwnerId: $newOwnerId)
}
    `;
export type TransferReportOwnerMutationFn = Apollo.MutationFunction<TransferReportOwnerMutation, TransferReportOwnerMutationVariables>;

/**
 * __useTransferReportOwnerMutation__
 *
 * To run a mutation, you first call `useTransferReportOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferReportOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferReportOwnerMutation, { data, loading, error }] = useTransferReportOwnerMutation({
 *   variables: {
 *      reportId: // value for 'reportId'
 *      newOwnerId: // value for 'newOwnerId'
 *   },
 * });
 */
export function useTransferReportOwnerMutation(baseOptions?: Apollo.MutationHookOptions<TransferReportOwnerMutation, TransferReportOwnerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TransferReportOwnerMutation, TransferReportOwnerMutationVariables>(TransferReportOwnerDocument, options);
      }
export type TransferReportOwnerMutationHookResult = ReturnType<typeof useTransferReportOwnerMutation>;
export type TransferReportOwnerMutationResult = Apollo.MutationResult<TransferReportOwnerMutation>;
export type TransferReportOwnerMutationOptions = Apollo.BaseMutationOptions<TransferReportOwnerMutation, TransferReportOwnerMutationVariables>;