import { RouteObject } from 'react-router-dom'
import React, { lazy } from 'react'

const Designer = lazy(() => import('./Designer'))
const Viewer = lazy(() => import('./DocumentViewer'))

export const DEVEXPRESS_PATH = {
  DESIGNER: '/dx/designer',
  VIEWER: '/dx/viewer',
}

const DevExpressRoutes:RouteObject[] = [
  {
    path: `designer/:reportId`,
    element: <Designer />
  },
  {
    path: `viewer/:reportId`,
    element: <Viewer />
  }
]

export default DevExpressRoutes
