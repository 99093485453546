import AppContext, { AppContextProps } from 'containers/context/Context'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { REPORT_PATH } from '../Report/Routes'
import { CryptoHelper } from 'containers/helper/Crypto/Crypto'
import { setAccessToken } from 'AccessToken'

const AutoLogin = () => {
  let navigate = useNavigate()
  const { rootState, rootDispatch } =
    React.useContext<AppContextProps>(AppContext)
  const [searchParams] = useSearchParams()
  useEffect(() => {
    let q = searchParams.get('q')
    const accessToken = CryptoHelper.decryption(q.replace(/ /g, '+'), 'base64')
    localStorage.setItem('key', CryptoHelper.encryption(accessToken))
    setAccessToken(accessToken)
    // sessionStorage.setItem(
    //   'tokenKey',
    //   CryptoHelper.decryption(q.replace(/ /g, '+'), 'base64')
    // )
    navigate(REPORT_PATH.DASHBOARD)
  }, [])

  return (
    <>
      <div>auto login</div>
    </>
  )
}

export default AutoLogin
